export default [
  {
    path: 'associacoes',
    component: () => import('@/views/pages/associations/AssociationsList'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Associações',
          disabled: true,
        },
      ],
    },
  },
]
