export default {
  install: (Vue) => {
    Vue.prototype.$apiError = {
      _: function (error) {
        // Timeout
        if (error.code === 'ECONNABORTED' || error.message === 'Network Error') {
          return 'Erro ao comunicar com o servidor, verifique sua conexao com a internet'
        }

        // A requisição foi feita e o servidor respondeu com um status fora do range 2xx
        if (error.response) {
          // Verifica se tem alguma mensagem para exibir
          if (typeof error.response.data?.error !== 'undefined') {
            return error.response.data.error
          }

          // Se não tiver define alugumas mensagens padrões de acordo com o status retornado
          if (error.response.status === 404) {
            return 'Não encontrado'
          } else if (error.response.status === 401) {
            return 'Não autorizado'
          } else if (error.response.status === 403) {
            return 'Você não tem permissão para realizar essa ação'
          }
        }

        return 'Ocorreu um erro'
      },

      fieldsValidation (error) {
        if (error.response.status !== 422) {
          return false
        }

        if (!error.response.data.fields) {
          return false
        }

        const fieldsErrors = {}

        error.response.data.fields.forEach(field => {
          if (!fieldsErrors[field.param]) {
            fieldsErrors[field.param] = []
          }

          fieldsErrors[field.param].push(field.msg)
        })

        return fieldsErrors
      },
    }
  },
}
