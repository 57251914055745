<template>
  <div>
    <canvas :id="id"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

Chart.register(ChartDataLabels)
Chart.register(...registerables)

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    id: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {}
  },

  mounted() {
    const ctx = document.getElementById(this.id).getContext('2d')
    new Chart(ctx, this.data)
  },
}
</script>
