export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
  },
  getters: {
    hasPermission: (state) => (name) => {

      // Admin (id === 1) have all permissions 
      const adminSearch = state.user.roles.filter((r) => r.id === 1)
      if (adminSearch.length > 0) {
        return true
      }

      for (const role of state.user.roles) {
        const permissionSearch = role.permissions.filter((p) => p.name === name)
        if (permissionSearch.length > 0) {
          return true
        }
      }

      return false
    },
  },
  mutations: {
    SET_TOKEN (state, payload) {
      state.token = payload
    },
    SET_USER (state, payload) {
      state.user = payload
    },
  },
}
