
export default (number, maximumFractionDigits = 2) => {
  if (!number) {
    number = 0
  }

  const currency = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits,
  })

  return currency.format(number)
}
