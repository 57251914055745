
export default (number, maximumFractionDigits = 2, minimumFractionDigits = 0) => {
  if (!number) {
    number = 0
  }

  const numberFormated = new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    maximumFractionDigits,
    minimumFractionDigits,
  })

  return numberFormated.format(number)
}
