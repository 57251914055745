import Vue from 'vue'
import date from '@/utils/filters/date'
import cpfCnpj from '@/utils/filters/cpfCnpj'
import currency from '@/utils/filters/currency'
import number from '@/utils/filters/number'

Vue.filter('date', date)
Vue.filter('cpfCnpj', cpfCnpj)
Vue.filter('currency', currency)
Vue.filter('number', number)
