export default [
  {
    path: 'tabela-de-contribuicao',
    component: () => import('@/views/pages/contribution-range/ContributionRangeList'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Tabela de Contribuição',
          disabled: true,
        },
      ],
    },
  },
]
