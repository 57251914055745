import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import layout from '@/store/modules/layout'
import user from '@/store/modules/user'
import contact from '@/store/modules/contact'
import answerSurvey from '@/store/modules/answer-survey'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    layout,
    user,
    contact,
    answerSurvey,
  },
  actions: {
    logout ({ commit }) {
      commit('user/SET_TOKEN', null)
      commit('user/SET_USER', {})
    },
    logoutContact ({ commit }) {
      commit('contact/SET_TOKEN', null)
      commit('contact/SET_CONTACT', {})
      commit('answerSurvey/SET_ANSWERS', [])
      commit('answerSurvey/SET_FLOW', [])
    },
  },
  plugins: [createPersistedState({
    key: 'vuex',
    reducer (val) {
      return {
        user: val.user,
        contact: val.contact,
        answerSurvey: val.answerSurvey,
      }
    },
  })],
})
