export default {
  namespaced: true,
  state: {
    loading: false,
    survey: {
      questions: [],
    },
    indexQuestion: 0,
    flow: [],
    answers: [],
  },
  getters: {
    questions: state => {
      return state.survey.questions
    },
    currentQuestion: state => {
      return state.survey.questions[state.indexQuestion]
    },
    lastQuestion: (state, getters) => {
      for (const question of getters.questions) {
        if (question.nextQuestionId) {
          continue
        }

        if (question.items.length <= 0) {
          return question
        }

        let hasAtLeastOneItemWithNexQuestion = false
        for (const item of question.items) {
          if (item.nextQuestionId) {
            hasAtLeastOneItemWithNexQuestion = true
          }
        }

        if (!hasAtLeastOneItemWithNexQuestion) {
          return question
        }
      }

      // fallback
      return state.survey.questions[state.survey.questions.length - 1]
    },
    isFirstQuestion: state => {
      return state.indexQuestion === 0
    },
    isLastQuestion: (state, getters) => {
      return getters.currentQuestion.id === getters.lastQuestion.id
    },
    findQuestionIndexById: state => id => {
      return state.survey.questions.findIndex(
        question => question.id === parseInt(id),
      )
    },
    questionsLength: state => {
      return state.survey.questions.length
    },
    getQuestionAnswer: state => id => {
      return state.answers.filter(answer => answer.questionId === id)
    },
  },
  actions: {
    nextQuestion({ commit, getters }, answer) {
      let nextQuestionId = null

      if (getters.currentQuestion.nextQuestionId) {
        nextQuestionId = getters.currentQuestion.nextQuestionId
      } else if (
        getters.currentQuestion.items.length > 0 &&
        !getters.currentQuestion.multiple
      ) {
        const item = getters.currentQuestion.items.find(
          i => i.id === answer.answer,
        )

        if (!item?.nextQuestionId) {
          // precaution, shouldn't fall here
          return
        }

        nextQuestionId = item.nextQuestionId
      }

      if (!nextQuestionId) {
        // precaution, shouldn't fall here
        return
      }

      const nextIndex = getters.findQuestionIndexById(nextQuestionId)

      commit('ADD_QUESTION_FLOW', nextQuestionId)
      commit('SET_INDEX_QUESTION', nextIndex)
    },
    previousQuestion({ commit, getters, state }) {
      const flowCurrentIndex = state.flow.indexOf(getters.currentQuestion.id)

      console.log({ flow: state.flow, flowCurrentIndex })

      if (typeof state.flow[flowCurrentIndex - 1] === 'undefined') {
        return
      }

      const previousQuestionId = state.flow[flowCurrentIndex - 1]
      const previousIndex = getters.findQuestionIndexById(previousQuestionId)

      console.log({ flowCurrentIndex, previousIndex, previousQuestionId })

      commit('SET_INDEX_QUESTION', previousIndex)
      commit('REMOVE_LAST_QUESTION_FLOW')
    },
    saveAnswer({ commit, state }, answer) {
      const existingAnswer = state.answers.filter(
        a => a.questionId === answer.questionId,
      )

      if (existingAnswer.length > 0) {
        commit('REMOVE_ANSWER', existingAnswer[0])
      }

      commit('ADD_ANSWER', answer)
    },
  },
  mutations: {
    SET_SURVEY(state, payload) {
      state.survey = payload
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_INDEX_QUESTION(state, payload) {
      state.indexQuestion = payload
    },
    SET_FLOW(state, payload) {
      state.flow = payload
    },
    SET_ANSWERS(state, payload) {
      state.answers = payload
    },
    ADD_ANSWER(state, payload) {
      state.answers.push(payload)
    },
    REMOVE_ANSWER(state, payload) {
      const index = state.answers
        .map(e => e.questionId)
        .indexOf(payload.questionId)

      state.answers.splice(index, 1)
    },
    ADD_QUESTION_FLOW(state, payload) {
      state.flow.push(payload)
    },
    REMOVE_LAST_QUESTION_FLOW(state) {
      state.flow.pop()
    },
  },
}
