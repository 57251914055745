export default [
  {
    path: '/inscricao/:eventId',
    component: () => import('@/views/Enrollment'),
    children: [
      {
        name: 'form',
        path: 'formulario',
        alias: '/',
        component: () => import('@/views/pages/enrollment/EnrollmentForm'),
      },
      {
        name: 'end',
        path: 'finalizar',
        component: () => import('@/views/pages/enrollment/EnrollmentEnd'),
      },
      {
        name: 'not-found',
        path: 'nao-encontrado',
        component: () => import('@/views/pages/enrollment/EnrollmentNotFound'),
      },
    ],
  },
]
