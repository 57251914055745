export default [
  {
    path: 'grupos/',
    component: () => import('@/views/pages/roles/RolesList'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Grupos',
          disabled: true,
        },
      ],
    },
  },
]
