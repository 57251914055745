export default [
  {
    path: '/download-recibos',
    component: () => import('@/views/DownloadReceipts'),
    children: [
      {
        path: 'login',
        alias: '/',
        component: () => import('@/views/pages/download-receipts/Login'),
        meta: {
          redirectIfAuthDownloadReceipts: true
        }
      },
      {
        path: 'selecionar',
        component: () => import('@/views/pages/download-receipts/Select'),
        meta: {
          authContact: true,
        },
      },
    ],
  },
]
