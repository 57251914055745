export default [
  {
    path: 'eventos',
    component: () => import('@/views/pages/events/EventsList'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Eventos',
          disabled: true,
        },
      ],
    },
  },
  {
    path: 'eventos/:id/inscritos',
    component: () => import('@/views/pages/events/EventsEnrolled'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Eventos',
          to: '/eventos',
          exact: true,
        },
        {
          text: 'Inscritos',
          disabled: true,
        },
      ],
    },
  },
]
