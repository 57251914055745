export default [
  {
    path: '/responder-pesquisa/:surveyId',
    component: () => import('@/views/AnswerSurvey'),
    children: [
      {
        name: 'answer-survey-login',
        path: 'login',
        alias: '/',
        component: () => import('@/views/pages/answer-survey/Login'),
      },
      {
        name: 'end-survey',
        path: 'finalizar',
        component: () => import('@/views/pages/answer-survey/EndSurvey'),
        meta: {
          authContact: true,
        },
      },
      {
        path: 'perguntas',
        component: () => import('@/views/pages/answer-survey/Questions'),
        meta: {
          authContact: true,
        },
      },
    ],
  },
]
