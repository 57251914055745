export default [
  {
    path: 'pesquisas',
    component: () => import('@/views/pages/surveys/SurveysList'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Pesquisas',
          disabled: true,
        },
      ],
    },
  },
  {
    path: 'pesquisas/:id/perguntas',
    component: () => import('@/views/pages/surveys/questions/QuestionsList'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Pesquisas',
          to: '/pesquisas',
          exact: true,
        },
        {
          text: 'Perguntas',
          disabled: true,
        },
      ],
    },
  },
  {
    path: 'pesquisas/:id/respostas',
    component: () => import('@/views/pages/surveys/answers/AnswersList'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Pesquisas',
          to: '/pesquisas',
          exact: true,
        },
        {
          text: 'Respostas',
          disabled: true,
        },
      ],
    },
  }
]
