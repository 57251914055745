export default [
  {
    path: 'prefeituras',
    component: () => import('@/views/pages/cities/CitiesList'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Prefeituras',
          disabled: true,
        },
      ],
    },
  },
]
