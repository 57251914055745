import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import pt from '@/utils/vuetify-pt-br'
import '@/sass/overrides.sass'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0067B0',
        secondary: '#5C5C5A',
        error: '#BD1622',
        success: '#008D36',
        warning: '#F8B133',
      },
    },
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
})
