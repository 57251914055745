import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'

import users from './modules/users'
import cities from './modules/cities'
import associations from './modules/associations'
import contributionRange from './modules/contribution-range'
import apportionments from './modules/apportionments'
import surveys from './modules/surveys'
import answerSurvey from './modules/answer-survey'
import downloadReceipts from './modules/download-receipts'
import receipts from './modules/receipts'
import announcements from './modules/announcements'
import roles from './modules/roles'
import events from './modules/events'
import enrollment from './modules/enrollment'

Vue.use(Router)

const routes = [
  {
    name: 'Login',
    path: '/login',
    component: () => import('@/views/pages/Login'),
    meta: { redirectIfAuth: true },
  },
  {
    name: 'Consultar',
    path: '/consultar',
    component: () => import('@/views/pages/search-apportionments/SearchApportionments'),
  },
  ...enrollment,
  ...answerSurvey,
  ...downloadReceipts,
  {
    path: '/',
    component: () => import('@/views/Index'),
    children: [
      {
        name: 'Dashboard',
        path: '',
        component: () => import('@/views/pages/Dashboard'),
        meta: { auth: true },
      },
      ...users,
      ...cities,
      ...apportionments,
      ...associations,
      ...surveys,
      ...contributionRange,
      ...receipts,
      ...announcements,
      ...roles,
      ...events,
    ],
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  var token = store.state.user.token
  var tokenContact = store.state.contact.token

  if (to.matched.some(record => record.meta.auth) && !token) {
    return next({ path: '/login' })
  }

  if (to.matched.some(record => record.meta.authContact) && !tokenContact) {
    if (to.params.surveyId) {
      return next({ path: `/responder-pesquisa/${to.params.surveyId}/login` })
    } else {
      return next({ path: `/download-recibos/login` })
    }
  }

  if (to.matched.some(record => record.meta.redirectIfAuth) && token) {
    return next({ path: '/' })
  }

  if (to.matched.some(record => record.meta.redirectIfAuthDownloadReceipts) && tokenContact) {
    return next({ path: '/download-recibos/selecionar' })
  }

  return next()
})

export default router
